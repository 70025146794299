import React from "react";
import { graphql } from "gatsby";
import Layout from "components/Layout";
import DiskItem from "components/DiskItem";

const Component = props => {
  const {
    data: {
      allContentfulDisk: { edges: disks }
    }
  } = props;
  return (
    <Layout>
      <h1>Diskographie</h1>
      {disks.map(({ node: disk }) => (
        <DiskItem disk={disk} key={disk.id} />
      ))}
    </Layout>
  );
};

export default Component;

export const Posts = graphql`
  query Disks {
    allContentfulDisk {
      edges {
        node {
          id
          title
          slug
          subTitle
          url
          image {
            id
            fluid(maxWidth: 400, maxHeight: 400) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            id
            description
            childMarkdownRemark {
              id
              html
            }
          }
        }
      }
    }
  }
`;
