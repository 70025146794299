import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Img from "gatsby-image";
import presets from "utils/presets";

const DiskItem = props => {
  const { disk } = props;
  return (
    <Item href={disk.url} target="_blank" rel="noopener noreferrer">
      <Preview>
        <Img fluid={disk.image.fluid} />
      </Preview>
      <Content>
        <Title>{disk.title}</Title>
        <SubTitle>{disk.subTitle}</SubTitle>
        <Description
          dangerouslySetInnerHTML={{
            __html: disk.description.childMarkdownRemark.html
          }}
        />
      </Content>
    </Item>
  );
};

DiskItem.propTypes = {
  disk: PropTypes.object.isRequired
};

export default DiskItem;

const Item = styled.a`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  box-sizing: border-box;
  box-shadow: 1px 2px 8px #d4d4d5;
  margin: 0 0 1rem 0;
  padding: 1rem;
  flex-basis: 300px;
  &:hover {
    box-shadow: 1px 2px 8px pink;
  }
  a {
    text-decoration: underline;
    &:hover {
      color: pink;
    }
  }
`;
const Preview = styled.div`
  width: 30%;
  margin-bottom: 1rem;
  ${presets.Tablet} {
    margin-bottom: 0;
  }
`;
const Content = styled.div`
  margin: 0 0 0 1rem;
  display: flex;
  flex-flow: wrap column;
  width: 70%;
`;
const Title = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;
const SubTitle = styled.div`
  margin-bottom: 1rem;
  color: hsla(0, 0%, 0%, 0.5);
`;
const Description = styled.div``;
